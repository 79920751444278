@import "~bootstrap/dist/css/bootstrap.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

* {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-datatable .p-column-header-content {
  color: #777 !important;
  white-space: nowrap;
  font-size: 12px;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 15px !important;
  font-weight: 400;
}

.p-datatable .p-paginator-bottom {
  border: none !important;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  width: 30px;
  height: 30px;
  font-size: 12px;
}

.p-paginator .p-paginator-current {
  font-size: 15px;
}

.p-fileupload-choose {

  background-color: #2c7be5;
  border-color: #2c7be5;
}


.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loading-indicator:after {
  content: '';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: 10001;
  color: white;
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.t-form {
  label {
    color: #7D7D7D !important;
    font-size: 14px !important;

  }
.form-control {
  border-radius: 20px !important;
  border: 1px solid #7C7C7C !important;
  padding: 8px 20px !important;
  color: #1E1E1E !important;
  font-size: 14px !important;
}
}

* {
  font-family: 'Nunito';
}

.slick-next::before, .slick-prev::before {
  display: none;
}

.custom-toast {
  background-color: #4f92fe !important;
  color: '#fff' !important;
}

.custom-toast-body {
  color: white !important; /* Custom text color */
}

.custom-toast-progress {
  background: #fff !important; /* Custom progress bar color */
}

.custom-toast-success {
  background-color: #4f92fe !important; /* You can adjust the color for success state if needed */
}

.custom-toast-body-success {
  color: white !important;
}

.custom-toast-progress-success {
  background: #4f92fe !important;
}

span.badge {
  word-break: break-word !important;
    white-space: normal !important;
    text-align: left !important;
}