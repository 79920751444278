
.c-header {
    background-color: #fff;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.05);
    padding: 14px 88px 14px 180px;
    }
    
    .c-nav-link {
    font-size: 13px;
    cursor: pointer;
    margin-left: 45px;
    }
    
    a {
    color: #000;
    }
    
    
    .active .c-nav-link p {
    color: blue;
    }
    
    .Mb2 {
    display: none;
    }
    
    
    @media (max-width: 768px) {
    .c-header {
    max-width: auto;
    padding: 15px 10px 0px 0px;
    
    }
    
    .c-nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 5px;
    margin-left: 20px;
    }
    
    .c-nav-link img {
    width: 20px;
    height: 20px;
    
    }
    
    .c-nav-link p {
    
    margin: 0;
    }
    
    
    .Mb {
    display: none;
    }
    
    .Mb2 {
    display: block;
    margin: 6px;
    }
    
    
    
    .Searchbar {
    max-width: 41px;
    margin-left: 1px;
    margin: 3px;
    
    }
    
    
    
    }
    
    @media only screen and (max-width: 768px) {
    .Box {
    margin-right: 0.7rem !important;
    }
    
    .col {
    flex-direction: column;
    }
    
    .btn {
    margin-top: 10px;
    }
    }